import {fromPromise, setup, assign} from "xstate";
import {Client, LoginModel, OauthType} from "shared/codegen/web_api_client";
import {handleApiError} from "shared/services/sentry";

async function loginToServer(googleToken: string): Promise<string> {
    const client = new Client();

    const payload = new LoginModel({
        token: googleToken,
        type: OauthType.Google
    });

    const result = await client.login(payload);
    return result.jwt;
}

async function sendJwtToDesktop(jwt: string, port: number) {
    await fetch(`http://localhost:${port}/success`, {
        method: "POST",
        body: JSON.stringify({jwt}),
        headers: {
            "Content-Type": "application/json"
        }
    });
}

const getDesktopPort = () => {
    // Get everything after the `?`
    const [, paramString] = window.location.href.split("?");

    // Return parameters
    const params = new URLSearchParams(paramString);
    if (params.has("fromDesktop") && params.has("redirectPort")) {
        return Number.parseInt(params.get("redirectPort")!);
    }
    return null;
};

const cookieName = "returnUrl";

const getRedirectCookie = () => {
    const b = document.cookie.match("(^|;)\\s*" + cookieName + "\\s*=\\s*([^;]+)");
    return b ? b.pop() : null;
};

const removeRedirectCookie = () => {
    document.cookie = cookieName + "=; Max-Age=-99999999;";
};

export const loginMachine = setup({
    actors: {
        finalize: fromPromise(({input}: {input: {token: string}}) => loginToServer(input.token)),
        sendToDesktop: fromPromise(({input}: {input: {jwt: string; port: number}}) =>
            sendJwtToDesktop(input.jwt, input.port)
        )
    },
    types: {
        context: {} as {
            token: string;
            jwt: string | null;
            error: string | null;
            redirectToDesktopPort: number | null;
        }
    }
}).createMachine({
    id: "login",
    initial: "idle",
    context: {
        token: "",
        jwt: null,
        error: null,
        redirectToDesktopPort: getDesktopPort()
    },
    states: {
        idle: {
            on: {
                GOOGLE: "googleCallback"
            }
        },
        googleCallback: {
            invoke: {
                src: "finalize",
                input: ({event}) => ({token: event.token}),
                onDone: [
                    {
                        target: "sendToDesktop",
                        actions: assign(({event}) => {
                            return {
                                jwt: event.output
                            };
                        }),
                        guard: ({context}) => context.redirectToDesktopPort != null
                    },
                    {
                        actions: () => {
                            let returnUrl = "/";
                            const redirectCookie = getRedirectCookie();
                            if (redirectCookie) {
                                removeRedirectCookie();
                                returnUrl = decodeURIComponent(redirectCookie);
                            }
                            window.location.href = returnUrl;
                        },
                        target: "redirecting"
                    }
                ],
                onError: {
                    actions: assign(({event}) => {
                        return {
                            error: handleApiError(event)
                        };
                    }),
                    target: "failure"
                }
            }
        },
        redirecting: {},
        sendToDesktop: {
            invoke: {
                src: "sendToDesktop",
                input: ({context}) => ({jwt: context.jwt!, port: context.redirectToDesktopPort!}),
                onDone: "sentToDesktop",
                onError: {
                    target: "failure",
                    actions: assign(({event}) => {
                        return {
                            error: handleApiError(event)
                        };
                    })
                }
            }
        },
        sentToDesktop: {},
        failure: {
            on: {
                ACK: "idle"
            }
        }
    }
});
