// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.newton-sidebar-inner {
    padding-top: 10px;
}

.lightbluebackground {
    background-color: #5696a9;
}

@media (min-width: 1000px) {
    #googleSignIn {
        transform: scale(1.5);
    }
}

.greyedOut div {
    color: #d3d3d3;
    background-color: darkgray;
    border-color: darkgray;
    cursor: default;
}
`, "",{"version":3,"sources":["webpack://./src/web.css"],"names":[],"mappings":"AAAA;IACI,iBAAiB;AACrB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI;QACI,qBAAqB;IACzB;AACJ;;AAEA;IACI,cAAc;IACd,0BAA0B;IAC1B,sBAAsB;IACtB,eAAe;AACnB","sourcesContent":[".newton-sidebar-inner {\n    padding-top: 10px;\n}\n\n.lightbluebackground {\n    background-color: #5696a9;\n}\n\n@media (min-width: 1000px) {\n    #googleSignIn {\n        transform: scale(1.5);\n    }\n}\n\n.greyedOut div {\n    color: #d3d3d3;\n    background-color: darkgray;\n    border-color: darkgray;\n    cursor: default;\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
