import {useEffect} from "react";
import React from "react";
import ReactDOM from "react-dom/client";
import {useMachine} from "@xstate/react";
import {loginMachine} from "./login_machine";
import {Modal, Spinner} from "flowbite-react";

import "../shared/shared.css";
import "../web.css";
import {setupSentry} from "shared/services/sentry";

const Login: React.FC = () => {
    const [current, send] = useMachine(loginMachine);
    const googleCallback = (credentialResponse: google.accounts.id.CredentialResponse) => {
        send({type: "GOOGLE", token: credentialResponse.credential});
    };

    useEffect(() => {
        const parent = document.getElementById("googleSignIn");
        if (parent == null) {
            return;
        }
        const scriptTag = document.createElement("script");
        scriptTag.src = "https://accounts.google.com/gsi/client";
        scriptTag.async = true;
        scriptTag.onload = () => {
            google.accounts.id.initialize({
                client_id:
                    "575384171784-mfiranlq0mer97gv1fik2btut5olds9e.apps.googleusercontent.com",
                hd: "myisaachealth.com",
                callback: googleCallback
            });
            google.accounts.id.renderButton(parent, {
                theme: "outline",
                type: "standard",
                size: "large"
            });
            google.accounts.id.prompt();
        };
        document.body.appendChild(scriptTag);
    });
    const showLoading = current.matches("googleCallback") || current.matches("redirecting");
    const greyedOut = showLoading ? "greyedOut" : "";
    return (
        <div className="flex flex-col items-center  h-full lightbluebackground">
            <img
                className="justify-self-center w-full md:w-4/5 lg:w-3/5"
                src="https://cdn.apps.myisaachealth.com/images/full_icon_white.png"
            ></img>

            {current.matches("sentToDesktop") ? (
                <div>Login complete, please return to the app.</div>
            ) : (
                <div className="grid place-items-center">
                    <div className={"col-start-1 row-start-1 " + greyedOut} id="googleSignIn"></div>
                    {showLoading && (
                        <div className="col-start-1 row-start-1">
                            <Spinner size="xl" className="" />
                        </div>
                    )}
                </div>
            )}
            <Modal show={current.matches("failure")}>
                <Modal.Header>An error has occurred</Modal.Header>
                <Modal.Body>
                    <p>{current.context.error}</p>
                </Modal.Body>
                <Modal.Footer>
                    <button onClick={() => send({type: "ACK"})}>Ok</button>
                </Modal.Footer>
            </Modal>
        </div>
    );
};
setupSentry(true);
const container = document.getElementById("root");
if (container === null) {
    throw new Error("No root element found");
}
const root = ReactDOM.createRoot(container);
root.render(<Login />);
